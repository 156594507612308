<template>
  <v-card>
    <div class="pa-5 mb-5">
      <form
        @submit.prevent="search"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="filterType"
              :items="filterTypes"
              item-text="label"
              label="Type de recherche"
              return-object
            />
            <v-checkbox
              v-if="showShouldEtablissementBePrincipal"
              v-model="shouldEtablissementBePrincipal"
              label="L'établissement est principal"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="query"
              label="Recherche"
              clearable
              data-vv-name="recherche"
              @input="search"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              type="submit"
              :disabled="!formValid"
              color="primary"
              v-text="'Rechercher'"
            />
          </v-col>
        </v-row>
      </form>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConseilLocalFilter',

  data: () => ({
    query: '',
    shouldEtablissementBePrincipal: false,
    filterType: { value: 'nom', label: 'Nom' },
    filterTypes: [
      { value: 'nom', label: 'Nom' },
      { value: 'code', label: 'Code' },
      { value: 'adresse_legale.ville', label: 'Commune' },
      { value: 'nom_etablissement', label: 'Nom d\'établissement scolaire' },
    ],
  }),

  computed: {
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
    showShouldEtablissementBePrincipal() {
      return this.filterType && (this.filterType.value === 'nom_etablissement');
    },
  },

  methods: {

    ...mapActions('conseilLocal', [
        'setFilters',
    ]),

    search() {
      let filters = [];
      if (this.query) {
        filters.push({
          name: this.filterType.value,
          value: this.query,
          shouldEtablissementBePrincipal: this.shouldEtablissementBePrincipal,
        });
      }
      this.$store.commit('conseilLocal/setFilters', filters);
    },
  },
};
</script>
