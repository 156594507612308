<template>
  <div>
    <v-card>
      <div class="pa-5 mb-5">
        <div v-if="isUserNational">
          <v-alert
            color="primary"
            dense
            type="info"
            :value="!currentCdpeId"
          >
            Veuillez sélectionner un <strong>Département</strong>
          </v-alert>
          <div
            class="text-right"
          >
            <v-autocomplete
              v-model="currentCdpeId"
              label="Département"
              item-value="id"
              :items="filterDepartements"
              :item-text="(item) => `${item.code} - ${item.nom}`"
            />
          </div>
        </div>
      </div>
    </v-card>
    <v-data-table
      light
      :headers="headers"
      class="elevation-3"
      :items="conseilLocaux"
      :sort-by="['nom']"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageText: 'Conseils par page:',
        itemsPerPageOptions: [20, 50, 100, 200]
      }"
    >
      <template v-slot:item.action="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click.stop
            >
              more_vert
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="canEditPerimetre"
              :to="{ name: 'edition-conseil-local', params: { conseilLocalId: item.cl_id, cdpeId: currentCdpeId }}"
            >
              <v-list-item-action
                :key="item.id"
              >
                Modifier
              </v-list-item-action>
            </v-list-item>

            <v-list-item 
              v-if="canDeletePerimetre"
              @click.stop="onRemove(item.cl_id)"
            >
              <v-list-item-action>
                Supprimer
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <v-progress-linear
        v-if="loading"
        v-slot:progress
        color="primary"
      />
    </v-data-table>
    <ConfirmedModal
      v-if="confirmDelete"
      :sentence="modalSentence"
      :btn-action="modalBtnAction"
      @close="confirmDelete = false"
      @confirmed="remove"
    />
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import ConfirmedModal from './../../reusableComponents/confirmedModal';

export default {
  name: 'ConseilLocalListe',
  components:{
    ConfirmedModal,
  },
  data: () => ({
    confirmDelete: false,
    currentCdpeId: 0,
    currentConseilLocalId: 0,
    headers: [
      {
        text: 'Action',
        sortable: false,
        value: 'action',
      },
      {
        text: 'Code',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Nom',
        sortable: true,
        value: 'nom',
      },
      {
        text: 'Commune',
        sortable: true,
        value: 'adresse_legale.ville',
      },
      {
        text: 'Telephone',
        sortable: false,
        value: 'telephone',
      },
      {
        text: 'Nombre adhérent(s)',
        sortable: false,
        value: 'nbParentsDontAdherents',
      },
    ],
    modalSentence: 'Êtes-vous sûr de vouloir supprimer ce conseil local ? Cette action est irreversible',
    modalBtnAction: 'Supprimer',
  }),

  computed: {
    ...mapState('conseilLocal', {
      loading: state => state.loading,
    }),

    ...mapGetters('conseilLocal', {
      conseilLocaux: 'getConseilLocaux',
    }),

    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      perimetreUser: 'getPerimetreUser',
      canEditPerimetre: 'canEditPerimetre',
      canDeletePerimetre: 'canDeletePerimetre',
    }),
  },

  watch: {
    currentCdpeId(cdpeId) {
      this.loadConseilLocaux({
        cdpeId,
      });
    },
  },

  created() {
    if (this.isUserNational) {
      this.loadFilterDepartements();
      return;
    }

    this.currentCdpeId = this.perimetreUser.perimetre_id;
  },


  methods: {
    ...mapActions('conseilLocal', {
      loadConseilLocaux: 'loadList',
      deleteConseilLocal: 'delete',
    }),
    ...mapActions('cdpe', {
      loadFilterDepartements: 'loadFilterDepartements',
    }),

    onRemove(conseilLocalId) {
      this.confirmDelete = true;
      this.currentConseilLocalId = conseilLocalId;
    },

    remove() {
      this.deleteConseilLocal({
        conseilLocalId: this.currentConseilLocalId,
        cdpeId: this.currentCdpeId,
      }).finally(() => {
        this.currentConseilLocalId = 0;
        this.confirmDelete = false;
      });

    },
  },
};
</script>
