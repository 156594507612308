var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('div',{staticClass:"pa-5 mb-5"},[(_vm.isUserNational)?_c('div',[_c('v-alert',{attrs:{"color":"primary","dense":"","type":"info","value":!_vm.currentCdpeId}},[_vm._v(" Veuillez sélectionner un "),_c('strong',[_vm._v("Département")])]),_c('div',{staticClass:"text-right"},[_c('v-autocomplete',{attrs:{"label":"Département","item-value":"id","items":_vm.filterDepartements,"item-text":function (item) { return ((item.code) + " - " + (item.nom)); }},model:{value:(_vm.currentCdpeId),callback:function ($$v) {_vm.currentCdpeId=$$v},expression:"currentCdpeId"}})],1)],1):_vm._e()])]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"light":"","headers":_vm.headers,"items":_vm.conseilLocaux,"sort-by":['nom'],"items-per-page":50,"footer-props":{
      itemsPerPageText: 'Conseils par page:',
      itemsPerPageOptions: [20, 50, 100, 200]
    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" more_vert ")])]}}],null,true)},[_c('v-list',[(_vm.canEditPerimetre)?_c('v-list-item',{attrs:{"to":{ name: 'edition-conseil-local', params: { conseilLocalId: item.cl_id, cdpeId: _vm.currentCdpeId }}}},[_c('v-list-item-action',{key:item.id},[_vm._v(" Modifier ")])],1):_vm._e(),(_vm.canDeletePerimetre)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.onRemove(item.cl_id)}}},[_c('v-list-item-action',[_vm._v(" Supprimer ")])],1):_vm._e()],1)],1)]}}])},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)}):_vm._e()],1),(_vm.confirmDelete)?_c('ConfirmedModal',{attrs:{"sentence":_vm.modalSentence,"btn-action":_vm.modalBtnAction},on:{"close":function($event){_vm.confirmDelete = false},"confirmed":_vm.remove}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }