<template>
  <div>
    <ConseilLocalFilter />
    <ConseilLocalListe />
  </div>
</template>
<script>
import ConseilLocalListe from '../../components/conseilLocal/recherche/Liste';
import ConseilLocalFilter from '../../components/conseilLocal/recherche/Filter';

export default {
  name: 'ConseilLocalSearchContainer',
  components: {
    ConseilLocalListe,
    ConseilLocalFilter,
  },
};
</script>
